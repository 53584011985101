import React from "react";
import "../../styles/ProjectDetails.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const DeepLearning = () => {
  return (
    <div className="project-details-container">
      {/* Banner Section */}
      <div className="project-image">
        <img
          src={"/assets/projects/DeepLearning/Landing.png"}
          alt={"BlueVisionary"}
          className="project-image"
        />
      </div>

      {/* Overview Section */}
      <div className="section overview">
        <h2>Overview</h2>
        <p>
          Focused on creating high-performing deep learning models across
          various architectures, including{" "}
          <strong>
            Deep Neural Networks (DNN), Convolutional Neural Networks (CNN) and
            Recurrent Neural Networks (RNN)
          </strong>
          . Projects targeted machine learning tasks such as image
          classification and linear regression, emphasizing model accuracy and
          performance.
        </p>
        <ul>
          <li>
            <strong>Deep Neural Network (DNN): </strong>This project works with
            the FashionMNIST dataset for image recognition task. It has the
            exact same format as MNIST (70,000 grayscale images of 28 × 28
            pixels each with 10 classes), but the images represent fashion items
            rather than handwritten digits, so each class is more diverse and
            the problem is significantly more challenging than MNIST
          </li>
          <li>
            <strong>Convolutional Neural Network (CNN): </strong>This project
            designed to assess knowledge and coding skill with Tensorflow as
            well as hands-on experience with training Convolutional Neural
            Network (CNN). The dataset used for this part is a specific dataset
            for this unit consisting of approximately 10,000 images of 20
            classes, each of which has approximately 500 images.
          </li>
          <li>
            <strong>Recurrent Neural Network (RNN): </strong>The theme of this
            project is about practical machine learning knowledge and skills in
            deep neural networks, word embedding and text analytics.
          </li>
        </ul>
      </div>

      {/* Github Link Section */}
      <div className="section github-link">
        <h2>Github Link</h2>
        <ul>
          <li>
            <strong>
              Github (DNN & CNN){" "}
              <i className="fab fa-github" style={{ marginRight: "5px" }}></i>:{" "}
            </strong>
            <a
              href="https://github.com/YiJieNG/yjProjects/tree/main/DNN_CNN"
              target="_blank"
              rel="noopener noreferrer"
            >
              github.com/YiJieNG/yjProjects/tree/main/DNN_CNN
            </a>
          </li>
          <li>
            <strong>
              Github (RNN){" "}
              <i className="fab fa-github" style={{ marginRight: "5px" }}></i>:{" "}
            </strong>
            <a
              href="https://github.com/YiJieNG/yjProjects/tree/main/RNN"
              target="_blank"
              rel="noopener noreferrer"
            >
              github.com/YiJieNG/yjProjects/tree/main/RNN
            </a>
          </li>
        </ul>
      </div>

      {/* Technologies Section */}
      <div className="section technologies">
        <h2>Technologies Used</h2>
        <p>Neural Networks, Python, PyTorch, Tensorflow</p>
      </div>

      {/* DNN Section */}
      <div className="section dnn">
        <h2>1. Deep Neural Networks (DNN)</h2>
        <ul>
          <li>
            Develop a feed-forward neural network with the architecture
            784→40(𝑅𝑒𝐿𝑈)→30(𝑅𝑒𝐿𝑈)→10(𝑠𝑜𝑓𝑡𝑚𝑎𝑥)
          </li>
          <li>Optimizer: SGD</li>
          <li>Learning Rate: 0.001</li>
          <li>Epochs Trained: 50</li>
        </ul>
        <p>
          <strong>a. Training Part 1:</strong>
        </p>
        <img
          src={"/assets/projects/DeepLearning/DnnPartOne.png"}
          alt={"BlueVisionary"}
          className="desc-image"
        />
        <p>
          <strong>b. Training Part 2:</strong>
        </p>
        <img
          src={"/assets/projects/DeepLearning/DnnPartTwo.png"}
          alt={"BlueVisionary"}
          className="desc-image"
        />
        <p>
          <strong>c. Training Result:</strong>
        </p>
        <img
          src={"/assets/projects/DeepLearning/DnnResult.png"}
          alt={"BlueVisionary"}
          className="desc-image"
        />
      </div>

      {/* CNN Section */}
      <div className="section cnn">
        <h2>2. Convolutional Neural Networks (CNN)</h2>
        <ul>
          <li>Top 25% candidate in Kaggle competition</li>
        </ul>
        <p>
          <strong>a. Model Architecture Part 1:</strong>
        </p>
        <img
          src={"/assets/projects/DeepLearning/ModelArchiOne.png"}
          alt={"BlueVisionary"}
          className="desc-image"
        />
        <p>
          <strong>b. Model Architecture Part 2:</strong>
        </p>
        <img
          src={"/assets/projects/DeepLearning/ModelArchiTwo.png"}
          alt={"BlueVisionary"}
          className="desc-image"
        />
        <p>
          <strong>c. Techniques Applied:</strong>
        </p>
        <ul>
          <li>
            Early stopping with patience of 3. Performance with no increase for
            valid accuracy 3 times in a row, training will then stop to avoid
            overfitting.{" "}
          </li>
          <li>
            Data mix up to avoid model memorising the pattern of input which
            caused overfitting. (Data mixed with adjacent data, then append to
            original data)
          </li>
          <li>
            Data Augmentation to avoid model memorising the pattern of input
            which caused overfitting. (Horizontal_flip = True, width &
            height_shift_range = 0.1)
          </li>
          <li>
            Loss = CE loss since OVA loss is more suitable for case of data with
            multiple labels.{" "}
          </li>
        </ul>
        <p>
          <strong>d. Parameter Setting:</strong>
        </p>
        <ul>
          <li>
            Learning rate: Among 0.01, 0.001 and 0.0001, 0.001 is the learning
            rate that perform the best testing accuracy.{" "}
          </li>
          <li>
            is_augmentation, num_channels, num_blocks, mean_pool, batch_norm and
            batch_size are all having the same results as the model tunned in
            assignment 1. The best parameters will be num_channels = 16,
            num_blocks = 4, mean_pool=True, batch_norm=True, batch_size = 16
          </li>
          <li>
            use_skip: False will perform a better performance in testing
            accuracy which is different in the model trained in Assignment 1.
            This is because skip connection aims to resolve gradient vanishing
            issue. In our case, gradient vanishing is not occurred which might
            lead to a result of adding a bias to the result from each block.
            Therefore, overfitting might occur in this case.
          </li>
          <img
            src={"/assets/projects/DeepLearning/FinalRecommendation.png"}
            alt={"FinalRecommendation"}
            className="desc-image"
          />
        </ul>
        <p>
          <strong>e. Loss & Training Accuracy: </strong>
          <img
            src={"/assets/projects/DeepLearning/Loss.png"}
            alt={"FinalRecommendation"}
            className="desc-image"
          />
        </p>
        <p>
          <strong>f. Training Result:</strong>
        </p>
        <img
          src={"/assets/projects/DeepLearning/CnnResult.png"}
          alt={"BlueVisionary"}
          className="desc-image"
        />
        <p>
          <strong>f. Ranking in Kaggle Leaderboard: </strong>
          <img
            src={"/assets/projects/DeepLearning/Leaderboard.png"}
            alt={"FinalRecommendation"}
            className="desc-image"
          />
        </p>
      </div>

      {/* RNN Section */}
      <div className="section rnn">
        <h2>3. Recurrent Neural Networks (RNN)</h2>
        <ul>
          <li>
            run_mode (self.run_mode) has three values (scratch, init-only, and
            init-fine-tune).{" "}
            <ul>
              <li>scratch means training the embedding matrix from scratch.</li>
              <li>
                init-only means only initializing the embedding matrix with a
                pretrained Word2Vect but not further doing fine-tuning that
                matrix.
              </li>
              <li>
                init-fine-tune means both initializing the embedding matrix with
                a pretrained Word2Vect and further doing fine-tuning that
                matrix.
              </li>
            </ul>
          </li>
          <li>
            network_type (self.network_type) has two values (uni-directional and
            bi-directional) which correspond to either Uni-directional RNN or
            Bi-directional RNN.
          </li>
          <li>
            embed_model (self.embed_model) specifes the pretrained Word2Vect
            model used.
          </li>
          <li>
            embed_size (self.embed_size) specifes the embedding size. Note that
            when run_mode is either init-only' or 'init-fine-tune', this
            embedding size is extracted from embed_model for dimension
            compatability.
          </li>
          <li>
            state_sizes (self.state_sizes) indicates the list of the hidden
            sizes of memory cells. For example, 𝑠𝑡𝑎𝑡𝑒_𝑠𝑖𝑧𝑒𝑠=[64,128] means that
            you have two hidden layers in your network with hidden sizes of 64
            and 128 respectively.
          </li>
        </ul>
        <p>
          <strong>a. RNN Part 1:</strong>
        </p>
        <img
          src={"/assets/projects/DeepLearning/RnnPartOne.png"}
          alt={"RnnPartOne"}
          className="desc-image"
        />
        <p>
          <strong>b. RNN Part 2:</strong>
        </p>
        <img
          src={"/assets/projects/DeepLearning/RnnPartTwo.png"}
          alt={"RnnPartTwo"}
          className="desc-image"
        />
        <p>
          <strong>c. Training:</strong>
        </p>
        <img
          src={"/assets/projects/DeepLearning/RnnTraining.png"}
          alt={"RnnTraining"}
          className="desc-image"
        />
      </div>

      {/* Footer Section */}
      <div className="footer">
        <p>&copy; Yi Jie Ng</p>
      </div>
    </div>
  );
};

export default DeepLearning;
