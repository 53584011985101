import React from "react";
import "../styles/About.scss";
import "../styles/Footer.scss";
import profileImage from "../assets/profile.jpg";
import { Link } from "react-router-dom";
import {
  FaGraduationCap,
  FaBriefcase,
  FaCode,
  FaProjectDiagram,
} from "react-icons/fa";

const About = () => {
  return (
    <div className="about-container">
      <div className="section intro">
        <div className="intro-header">
          <img src={profileImage} alt="Profile" className="profile-image" />
          <h2>Hello, I am Ng Yi Jie!</h2>
        </div>
        <p>
          I am a graduate Master’s student in Artificial Intelligence at Monash
          University with a strong foundation in computer science and a deep
          passion for AI research and software development. My academic journey
          has been enriched with hands-on projects in machine learning, deep
          learning and web development.
        </p>
        <p>
          I enjoy creating innovative solutions, including websites, games and
          mobile apps. In my free time, I play basketball 🏀 and listen to music
          🎧 to unwind. These hobbies inspire my creativity and help me maintain
          a balanced life.
        </p>
      </div>
      <div className="section education">
        <h2>
          <FaGraduationCap /> Education
        </h2>
        <div className="content">
          <h3>Monash University, Clayton, Australia</h3>
          <p>Master of Artificial Intelligence (Feb 2023 - Dec 2024)</p>
          <ul>
            <li>
              <i>
                <b>WAM: </b>
              </i>
              81.538,{" "}
              <i>
                <b>GPA: </b>
              </i>
              3.615/4.000
            </li>
            <li>
              <i>
                <b>Award: </b>
              </i>
              Commendation for High Distinctions top student scorer
            </li>
            <li>
              <i>
                <b>Relevant Coursework with High Distinctions: </b>
              </i>
              Machine Learning, Deep Learning, Natural Language Processing, User
              Interface Design and Usability, Professional Practice, IT research
              methods, Fundemental in Artificial Intelligence
            </li>
          </ul>
          <h3>Bachelor of Computer Science</h3>
          <p>Monash University (Oct 2019 - Dec 2022)</p>
          <ul>
            <li>
              <i>
                <b>Award: </b>
              </i>
              Best in Class in AI: Logic and Search (Only awarded to two
              candidates)
            </li>
          </ul>
        </div>
      </div>

      <div className="section experience">
        <h2>
          <FaBriefcase /> Professional Experience
        </h2>
        <div className="content">
          <h3>LS Smart Machinery</h3>
          <p>Backend Developer (Nov 2021 - Feb 2022)</p>
          <ul>
            <li>Built RESTful APIs for Yabee food ordering platform</li>
            <li>Enhanced backend performance with GoLang and Supabase</li>
          </ul>
        </div>
      </div>

      <div className="section projects">
        <h2>
          <FaProjectDiagram /> Projects
        </h2>
        <p>
          <Link to="/Projects" className="projects-link">
            Find out more about my projects here
          </Link>
        </p>

        <div className="content">
          <h3>BlueVisionary</h3>
          <ul>
            <li>
              <i>
                <b>Technologies: </b>
              </i>
              React, Flask, Python, MySQL
            </li>
            <li>
              Aimed at marine conservation with features like quizzes, a sea
              turtle minigame, and a recycling impact tracker
            </li>
            <li>
              Consolidate the <b>technical and soft skills gained over time</b>,
              including (but not limited to) image recognition, deep learning,
              project management, UI/UX design, React with Redux, full-stack
              development and web hosting.
            </li>
          </ul>
          <h3>Ingredient-Recipe Generator</h3>
          <ul>
            <li>
              <i>
                <b>Technologies: </b>
              </i>
              NLP, PyTorch
            </li>
            <li>
              Generated recipes based on ingredients using sequence-to-sequence
              models
            </li>
          </ul>
        </div>
      </div>

      <div className="section skills">
        <h2>
          <FaCode /> Skills
        </h2>
        <div className="content">
          <p>
            <i>
              <strong>Languages: </strong>
            </i>
            English, Mandarin, Malay
          </p>
          <p>
            <strong>
              <i>Technical Skills: </i>
            </strong>{" "}
            Python, Java, GoLang, SQL, React, AI & ML (NLP, Reinforcement
            Learning, DNN, CNN, RNN)
          </p>
          <p>
            <i>
              <strong>Soft Skills: </strong>{" "}
            </i>
            Team-oriented, Leadership, Problem-solving
          </p>
        </div>
      </div>
      <div className="footer">
        <p>© {new Date().getFullYear()} Ng Yi Jie</p>
      </div>
    </div>
  );
};

export default About;
