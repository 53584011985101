import React from "react";
import "../styles/Contact.scss";
import "../styles/Navigation.scss";
import { FaLinkedin, FaGithub, FaEnvelope, FaPhone } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="section">
        <h2>Contact Me</h2>
        <ul className="contact-list">
          <li>
            <FaEnvelope className="icon" />
            <span>
              <b>ngyijie721@gmail.com</b>
            </span>
          </li>
          <li>
            <FaPhone className="icon" />
            <span>
              <b>(+60)106631817</b>
            </span>
          </li>
          <li>
            <FaLinkedin className="icon" />
            <a
              href="https://linkedin.com/in/yi-jie-ng-b24281220"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
          <li>
            <FaGithub className="icon" />
            <a
              href="https://github.com/YiJieNG"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contact;
