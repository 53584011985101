import React from "react";
import "../../styles/ProjectDetails.scss";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Link } from "react-router-dom";

const CsProject = () => {
  return (
    <div className="project-details-container">
      {/* Banner Section */}
      <div className="project-image">
        <img
          src={"/assets/projects/ComputerScience/Landing.png"}
          alt={"ComputerScience Banner"}
          className="project-image"
        />
      </div>

      {/* Overview Section */}
      <div className="section model-training">
        <h2>Overview</h2>
        <p>
          Projects completed during{" "}
          <strong>Bachelor of Computer Science</strong> at Monash University
          focused on <strong>finding optimal solutions</strong> using various
          strategies and algorithms including Dijkstra's algorithm, dynamic
          programming, and A* (Ukennon). Additionally,{" "}
          <strong>smaller projects</strong> such as the Space Invader Game and
          mobile design prototypes were also undertaken.
        </p>
      </div>

      {/* Github Link Section */}
      <div className="section github-link">
        <h2>Github Link</h2>
        <ul>
          <li>
            <strong>
              GitHub{" "}
              <i className="fab fa-github" style={{ marginRight: "5px" }}></i>:{" "}
            </strong>{" "}
            <a
              href="https://github.com/YiJieNG/yjProjects"
              target="_blank"
              rel="noopener noreferrer"
            >
              github.com/YiJieNG/yjProjects
            </a>
          </li>
        </ul>
      </div>

      {/* Challenges */}
      <div className="section challenges">
        <h2>Challenges</h2>
        <p>
          {" "}
          <p>
            {" "}
            During my Bachelor’s degree, I worked on multiple projects requiring
            diverse skills. Most of these projects were implemented in
            environments like the console or terminal. However, a{" "}
            <strong>
              lack of proper folder structure and organization
            </strong>{" "}
            meant that some projects are now inaccessible or cannot be reopened.{" "}
          </p>{" "}
          <p>
            {" "}
            This experience taught me the critical importance of organizing and
            structuring projects effectively. As a result, I have{" "}
            <strong>adopted better practices in my Master’s degree</strong>,
            ensuring all projects are saved and organized systematically to
            prevent similar issues in the future.{" "}
          </p>{" "}
        </p>
      </div>

      {/* Footer Section */}
      <div className="footer">
        <p>&copy; Ng Yi Jie</p>
      </div>
    </div>
  );
};

export default CsProject;
