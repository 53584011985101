import React, { useEffect } from "react";
import "../styles/Home.scss";
import "../styles/Footer.scss";
import profileImage from "../assets/profile.jpg";
import projectsImage from "../assets/Projects.png";
import aboutImage from "../assets/About.png";
import contactImage from "../assets/Contact.png";
import { Link } from "react-router-dom";

const Home = () => {
  const handleScroll = () => {
    document
      .getElementById("content-links")
      .scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // Adding a class to trigger the fade-in animation after the component mounts
    setTimeout(() => {
      document.querySelector(".profile-section").classList.add("fade-in");
    }, 200);
  }, []);

  return (
    <div className="home-container">
      {/* Profile Section */}
      <div className="profile-section">
        <img src={profileImage} alt="Profile" className="profile-image" />
        <h1 className="profile-name">Yi Jie Ng</h1>
        <p className="profile-description">
          Graduate Masters student studying Artificial Intelligence at Monash
          University with a strong passion for AI research and software
          development. Proven expertise in machine learning and a keen interest
          in problem-solving, with a focus on creating high-quality and
          impactful AI applications.
        </p>

        {/* Scroll Down Animation */}
        <div className="scroll-down" onClick={handleScroll}>
          {/* <p>Scroll Down</p> */}
          <div className="arrow">▼</div>
        </div>
      </div>

      {/* Content Links Section */}
      <div id="content-links" className="content-links">
        <Link to={"/projects"} style={{ textDecoration: "none" }}>
          <div className="link-card">
            <h3>Projects</h3>
            <img src={projectsImage} className="content-image"></img>
            <p>Check out my recent work and personal projects.</p>
          </div>
        </Link>
        <Link to={"/about"} style={{ textDecoration: "none" }}>
          <div className="link-card">
            <h3>About Me</h3>
            <img src={aboutImage} className="content-image"></img>
            <p>Learn more about my background and skills.</p>
          </div>
        </Link>
        <Link to={"/contact"} style={{ textDecoration: "none" }}>
          <div className="link-card">
            <h3>Contact</h3>
            <img src={contactImage} className="content-image"></img>
            <p>Get in touch with me for collaboration or queries.</p>
          </div>
        </Link>
      </div>
      <div className="footer">
        <p>© {new Date().getFullYear()} Ng Yi Jie</p>
      </div>
    </div>
  );
};

export default Home;
