import React from "react";
import "../../styles/ProjectDetails.scss";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Link } from "react-router-dom";

const ImageRecognition = () => {
  return (
    <div className="project-details-container">
      {/* Banner Section */}
      <div className="project-image">
        <img
          src={"/assets/projects/ImageRecognition/LandingImageRecognition.png"}
          alt={"ImageRecognition"}
          className="project-image"
        />
      </div>

      {/* Overview Section */}
      <div className="section model-training">
        <h2>Overview</h2>
        <p>
          In this project, a pre-trained YOLOv9c model is utilized with{" "}
          <strong>transfer learning</strong> to enhance the precision of plastic
          item detection. By leveraging knowledge from{" "}
          <strong>large-scale object detection datasets</strong>, transfer
          learning allows the model to effectively recognize various types of
          plastic waste. Rather than training the model from scratch, it is
          <strong> fine-tuned</strong> on a specialized dataset of plastic waste
          images, enabling it to <strong>adapt its parameters </strong> for
          accurate detection and classification of different plastic items in
          user-submitted images.
        </p>
        <img
          src={"/assets/projects/ImageRecognition/Overview.png"}
          alt={"Overview"}
          className="desc-image"
        />
      </div>

      {/* Website Info Section */}
      <div className="section website-info">
        <h2>Github Information</h2>
        <ul>
          <li>
            <strong>
              GitHub{" "}
              <i className="fab fa-github" style={{ marginRight: "5px" }}></i>:{" "}
            </strong>{" "}
            <a
              href="https://github.com/YiJieNG/Image-Recognition-for-Plastic-Item"
              target="_blank"
              rel="noopener noreferrer"
            >
              github.com/YiJieNG/Image-Recognition-for-Plastic-Item
            </a>
          </li>
        </ul>
      </div>

      {/*Data Preprocessing Section */}
      <div className="section data-preprocessing">
        <h2>Data Preprocessing</h2>
        <p>
          a. Merge training datasets which <strong>contain same labels</strong>{" "}
          of plastic bag, plastic bottle, plastic container, plastic cup,
          plastic cup, plastic straw and plastic utensil from{" "}
          <li>
            <a
              href="https://universe.roboflow.com/asia-pacific-university-msg4d/plastic-detection-o3dr4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Plastic detection Computer Vision Project
            </a>
          </li>
          <li>
            <a
              href="https://universe.roboflow.com/yolov5-6agzx/plastic-waste-management-6p8kw"
              target="_blank"
              rel="noopener noreferrer"
            >
              Plastic Waste Management Computer Vision Project
            </a>
          </li>
        </p>
        <p>
          b. Images in the merged datasets are{" "}
          <strong>resized to 640x640</strong> resolution and{" "}
          <strong>augmented with multiple strategies</strong> to benefit from
          enhanced model generalization and robustness. These augmentations
          simulate various real-world scenarios. By diversifying the training
          data through these augmentations, the model becomes{" "}
          <strong>
            more capable of detecting plastic items in varied orientations,
            lighting conditions and deformations{" "}
          </strong>
          , leading to improved accuracy and performance across different
          environmental conditions. The resizing ensures that the model
          <strong> processes images consistently</strong>, improving both
          detection accuracy and computational efficiency during training and
          inference.
        </p>
        <img
          src={"/assets/projects/ImageRecognition/Augmentation.png"}
          alt={"ModelTraining"}
          className="desc-image"
        />
        <p>
          c. Dataset is split into{" "}
          <strong>training (80%), validating (10%) and testing (10%) </strong>
          sets with paths listed under data.yml file.
        </p>
        <img
          src={"/assets/projects/ImageRecognition/Yaml.png"}
          alt={"ModelTraining"}
          className="desc-image"
        />
      </div>

      {/*Model Training Section */}
      <div className="section model-training">
        <h2>Model Training</h2>
        <p>
          The YOLOv9c model is trained using a{" "}
          <strong>
            combination of loss functions, including box loss, classification
            loss and distribution focal loss (dfl)
          </strong>{" "}
          to optimize the detection of plastic items within images. The training
          process runs for 100 epochs, during which the model progressively
          improves its predictions by minimizing these loss metrics.
        </p>
        <img
          src={"/assets/projects/ImageRecognition/ModelTraining.png"}
          alt={"ModelTraining"}
          className="desc-image"
        />
      </div>

      {/* Evaluation Section */}
      <div className="section evaluation">
        <h2>Evaluation</h2>
        <p>
          The total training duration took around <strong>15 hours</strong>.
          After each epoch, the model’s performance is assessed using key
          metrics such as{" "}
          <strong>
            precision (P), recall (R), mean Average Precision (mAP) at 50%
            (mAP50) and mAP at 50-95% (mAP50-95)
          </strong>
          . These metrics provide a detailed evaluation of the model’s
          effectiveness in accurately detecting and classifying plastic items.
        </p>
        <img
          src={"/assets/projects/ImageRecognition/Evaluation.png"}
          alt={"Evaluation"}
          className="desc-image"
        />
      </div>

      {/* Training Analysis Section */}
      <div className="section evaluation">
        <h2>Training Analysis</h2>
        <p>
          According to the accuracies observed during the model training
          process, the YOLOv9c model exhibits excellent performance in detecting
          and classifying plastic items. Precision values ranging{" "}
          <strong>from 0.8 to 1.0</strong> demonstrate the model's high accuracy
          in correctly identifying plastic waste, while recall values{" "}
          <strong>between 0.7 and 0.9</strong> indicate its strong ability to
          detect a substantial proportion of plastic items in the dataset. The
          mean Average Precision (mAP50), which evaluates the overall detection
          accuracy, <strong>ranges from 0.7 to 1.0</strong>, highlighting the
          model's reliability in making precise predictions. Additionally, the
          mAP across multiple Intersection over Union (IoU) thresholds
          (mAP50-95) averages <strong>between 0.7 and 0.85</strong>,
          illustrating the model's capacity to generalize well across varying
          levels of detection difficulty.
        </p>
        <img
          src={"/assets/projects/ImageRecognition/TrainingAnalysis.png"}
          alt={"TrainingAnalysis"}
          className="desc-image"
        />
        <p>
          In conclusion, the YOLOv9c model has demonstrated strong performance
          in detecting and classifying plastic waste through{" "}
          <strong>
            consistent improvements in both training and validation metrics
          </strong>
          . High precision and recall values indicate that the model is capable
          of{" "}
          <strong>
            accurately identifying and capturing a large proportion of plastic
            items
          </strong>{" "}
          in the dataset, while the mAP50 and mAP50-95 scores reflect its{" "}
          <strong>robust detection performance</strong> across various IoU
          thresholds. This high level of accuracy and generalization suggests
          that the{" "}
          <strong>model is well-suited for real-world applications</strong> in
          plastic waste detection, contributing effectively to marine
          conservation efforts.
        </p>
      </div>

      {/*Result Analysis Section */}
      <div className="section result-analysis">
        <h2>Result Analysis</h2>
        <p>
          Overall, the blue line representing "all classes" highlights strong
          performance, achieving a{" "}
          <strong>
            precision score of 1.00 at a confidence threshold of 0.974
          </strong>
          . This indicates that, across all plastic categories, the model is
          highly effective at making correct predictions with{" "}
          <strong>very few false positives at higher confidence levels</strong>.
        </p>
        <p>
          <li>
            When analyzing individual classes, the precision for detecting{" "}
            <strong>plastic bags remains consistently high</strong>, closely
            following the overall precision curve. This indicates that the model
            excels in identifying plastic bags with great accuracy.
          </li>
          <li>
            <strong>Plastic bottles also demonstrate strong performance</strong>
            , with reliable detection across different confidence levels.
          </li>
          <li>
            The curves for <strong>plastic containers and cups</strong> follow a
            similar trend, showing a{" "}
            <strong>steady increase in precision</strong> as confidence rises.
          </li>
          <li>
            However, precision for detecting{" "}
            <strong>plastic straws rises more gradually </strong>
            compared to other categories, suggesting the model may find it{" "}
            <strong>more challenging</strong> to distinguish straws at lower
            confidence levels.
          </li>
          <li>
            Plastic utensils exhibit the{" "}
            <strong>most variation in precision</strong>, starting lower than
            other categories but showing improvement at higher confidence
            thresholds. This indicates that the model{" "}
            <strong>requires greater certainty when detecting utensils</strong>,
            but its performance improves as confidence increases.
          </li>
        </p>
        <img
          src={"/assets/projects/ImageRecognition/ResultAnalysis.png"}
          alt={"ResultAnalysis"}
          className="desc-image"
        />
        <p>
          In conclusion, the model achieves{" "}
          <strong>excellent precision at high confidence thresholds</strong>{" "}
          across most plastic item categories, particularly{" "}
          <strong>plastic bags and bottles</strong>. While detection of more
          <strong>
            {" "}
            challenging items like plastic straws and utensils
          </strong>{" "}
          presents room for improvement, especially at lower confidence levels,
          the model demonstrates robust performance overall. The high precision
          across all categories at elevated confidence levels makes the model
          highly effective for real-world applications in detecting plastic
          waste.
        </p>
      </div>

      {/* Footer Section */}
      <div className="footer">
        <p>&copy; BlueVisionary. All rights reserved.</p>
      </div>
    </div>
  );
};

export default ImageRecognition;
