import React from "react";
import "../../styles/ProjectDetails.scss";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Link } from "react-router-dom";

const VolunUiDesign = () => {
  return (
    <div className="project-details-container">
      {/* Banner Section */}
      <div className="project-image">
        <img
          src={"/assets/projects/VolunUiDesign/Landing.png"}
          alt={"Multi Agent collaboration"}
          className="project-image"
        />
      </div>

      {/* Overview Section */}
      <div className="section model-training">
        <h2>Overview</h2>
        <p>
          This project aims to design and deliver a prototype of mobile
          application that helps users find long-term volunteer positions that
          suit their needs. Prototypes designed include: Low-Fidelity Prototype
          and High-Fidelity Prototype using Figma. The features designed
          included:
        </p>
        <p>
          <ul>
            <li>Dashboard</li>
            <li>Search & Filter</li>
            <li>Opportunity Details</li>
            <li>Opportunity Application</li>
            <li>Calender</li>
            <li>Rate & Review</li>
            <li>Achievements</li>
            <li>Settings</li>
          </ul>
        </p>
      </div>

      {/* Challenges */}
      <div className="section challenges">
        <h2>Challenges</h2>
        <p>
          {" "}
          <p>
            {" "}
            Being a <strong>group project</strong>, there were numerous
            conflicts during discussions especially when{" "}
            <strong>
              merging design ideas, distributing tasks and ensuring effective
              communication
            </strong>
            . Additionally, as the{" "}
            <strong>only member with experience in low-fidelity drawing</strong>
            , I faced a disproportionate workload, which added significant
            stress.{" "}
          </p>{" "}
          <p>
            {" "}
            This experience taught me the importance of{" "}
            <strong>
              effective communication, equitable task delegation and setting
              clear expectations
            </strong>{" "}
            within a team. It also highlighted the{" "}
            <strong>need for self-advocacy</strong> to prevent burnout and the
            value of leveraging each team member's strengths to create a
            balanced workload.{" "}
          </p>{" "}
        </p>
      </div>

      {/* Storyboard Section */}
      <div className="section storyboard">
        <h2>Storyboard: Michael</h2>
        <img
          src={
            "/assets/projects/VolunUiDesign/LowFidelity/Storyboard_Michael.png"
          }
          alt={"Storyboard: Michael"}
          className="desc-image"
        />
      </div>

      {/* Low Fidelity Section */}
      <div className="section low-fidelity">
        <h2>Low Fidelity Prototype</h2>
        <img
          src={"/assets/projects/VolunUiDesign/LowFidelity/Dashboard.jpg"}
          alt={"Dashboard"}
          className="small-image"
        />
        <img
          src={"/assets/projects/VolunUiDesign/LowFidelity/opportunity.jpg"}
          alt={"opportunity"}
          className="small-image"
        />
        <img
          src={"/assets/projects/VolunUiDesign/LowFidelity/filter.jpg"}
          alt={"filter"}
          className="small-image"
        />
        <img
          src={"/assets/projects/VolunUiDesign/LowFidelity/Search.jpg"}
          alt={"search"}
          className="small-image"
        />
        <img
          src={"/assets/projects/VolunUiDesign/LowFidelity/rate.jpg"}
          alt={"rate"}
          className="small-image"
        />
        <img
          src={"/assets/projects/VolunUiDesign/LowFidelity/calendar.jpg"}
          alt={"calender"}
          className="small-image"
        />
      </div>

      {/* High Fidelity Section */}
      <div className="section low-fidelity">
        <h2>High Fidelity Prototype</h2>
        <img
          src={"/assets/projects/VolunUiDesign/1.png"}
          alt={"High Fidelity Prototype 1"}
          className="desc-image"
        />
        <img
          src={"/assets/projects/VolunUiDesign/2.png"}
          alt={"High Fidelity Prototype 2"}
          className="desc-image"
        />
        <img
          src={"/assets/projects/VolunUiDesign/3.png"}
          alt={"High Fidelity Prototype 3"}
          className="desc-image"
        />
      </div>

      {/*High Fidelity Prototype Showcase*/}
      <div className="section showcase">
        <h2>High Fidelity Prototype Showcase</h2>
        <div className="video-container">
          <video controls width="640" height="360">
            <source src="/videos/UiShowcase.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* Footer Section */}
      <div className="footer">
        <p>&copy; Ng Yi Jie</p>
      </div>
    </div>
  );
};

export default VolunUiDesign;
