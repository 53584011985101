import React from "react";
import "../../styles/ProjectDetails.scss";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Link } from "react-router-dom";

const ResearchVideo = () => {
  return (
    <div className="project-details-container">
      {/* Overview Section */}
      <div className="section model-training">
        <h2>Overview</h2>
        <p>
          A 90 second video that will be sent to a number of investment
          companies seeking opportunities. We assume that the business
          engagement staff are seeking to communicate to investment companies
          and other organisations (such as private donors and charities) what
          the nature of a specific piece of research is.{" "}
        </p>
        <p>
          Such organisations have staff with a good technical knowledge but who
          are not usually specialists in the specific research area. The only
          goal of the video is to stimulate interest in the organizations to
          find out more about the research. Therefore my video will be used to
          introduce the core technical innovations and the significance of these
          to gain potential meetings with members of the organisations at which
          the detail and opportunities can be discussed further.
        </p>
        <p>
          <ul>
            <li>
              <strong>Paper selected for the video: </strong>
              <a
                href="https://dl.acm.org/doi/pdf/10.1145/3453800.3453803"
                target="_blank"
                rel="noopener noreferrer"
              >
                Thanh Trieu, N., Pottier, B., Rodin, V., & Xuan Huynh, H. (2021,
                January). Interpretable Machine Learning for Meteorological
                Data. In 2021 The 5th International Conference on Machine
                Learning and Soft Computing (pp. 11-17).
              </a>
            </li>
          </ul>
        </p>
      </div>

      {/* Challenges */}
      <div className="section challenges">
        <h2>Challenges</h2>
        <p>
          {" "}
          <p>
            {" "}
            This was my <strong>first time</strong> recording and editing a
            video, which made it a time-consuming process as I had to conduct
            extensive research to complete the task. Additionally, as this is my
            <strong> first semester pursuing a Master in AI</strong>, I lack
            in-depth knowledge of Machine Learning, which further increased the
            difficulty of fully understanding the paper.{" "}
          </p>{" "}
          <p>
            {" "}
            This experience taught me the importance of{" "}
            <strong>
              stepping out of my comfort zone embracing new challenges, and
              being patient
            </strong>{" "}
            with myself while learning new skills. It also highlighted the{" "}
            <strong>value of thorough research and persistence</strong> in
            overcoming initial knowledge gaps, as well as the need to seek
            guidance or collaborate with others when tackling complex tasks.{" "}
          </p>{" "}
        </p>
      </div>

      {/*Showcase*/}
      <div className="section showcase">
        <h2>Showcase Video</h2>
        <div className="video-container">
          <video controls width="640" height="360">
            <source src="/videos/RsShowcase.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* Footer Section */}
      <div className="footer">
        <p>&copy; Ng Yi Jie</p>
      </div>
    </div>
  );
};

export default ResearchVideo;
