import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "../styles/Navigation.scss"; // Import custom styles for the nav

const Navigation = () => (
  <Navbar bg="light" expand="lg">
    <Navbar.Brand href="/" className="navbar-brand">
      NG YI JIE
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ms-auto" style={{ paddingRight: "50px" }}>
        {" "}
        {/* <NavLink
          exact
          className="nav-link"
          activeClassName="active-link"
          to="/"
        >
          Home
        </NavLink> */}
        <NavLink
          className="nav-link"
          activeClassName="active-link"
          to="/projects"
        >
          Projects
        </NavLink>
        <NavLink className="nav-link" activeClassName="active-link" to="/about">
          About
        </NavLink>
        <NavLink
          className="nav-link"
          activeClassName="active-link"
          to="/contact"
        >
          Contact
        </NavLink>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default Navigation;
