import React from "react";
import "../../styles/ProjectDetails.scss";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Link } from "react-router-dom";

const MultiAgent = () => {
  return (
    <div className="project-details-container">
      {/* Banner Section */}
      <div className="project-image">
        <img
          src={"/assets/projects/MultiAgent/Landing.png"}
          alt={"Multi Agent collaboration"}
          className="project-image"
        />
      </div>

      {/* Overview Section */}
      <div className="section model-training">
        <h2>Overview</h2>
        <p>
          A project for multiple agents in a square grid world to learn a simple
          transport task that requires coordination between the agents using
          Deep-Q to let the agents learn this task. The agent’s task split into
          two parts that now require a handover between two agents. Game theory
          is applied to analyse the behaviour of these agents.
        </p>
        <p>
          <ul>
            <li>
              Agents have the four actions that they can execute: move
              north/move south/move west/move east.
            </li>
            <li>Each agent starts at a random location.</li>
            <li>
              Items need to be transported from a location A to a location B.
            </li>
            <li>
              These locations are now fixed (A in the top-left corner, B in the
              bottom-right corner).
            </li>
            <li>
              There are now two types of agents: Type 1 can pick up at A but not
              deliver at B.
            </li>
            <li>Type 2 can deliver at B but not pick up at A.</li>
            <li>
              A successful transport requires a handover between a Type 1 agent
              and a Type 2 agent.
            </li>
          </ul>
        </p>
        <img
          src={"/assets/projects/MultiAgent/Overview.png"}
          alt={"MA rules overview"}
          className="desc-image"
        />
      </div>

      {/* Github Link Section */}
      <div className="section github-link">
        <h2>Github Link</h2>
        <ul>
          <li>
            <strong>
              GitHub{" "}
              <i className="fab fa-github" style={{ marginRight: "5px" }}></i>:{" "}
            </strong>{" "}
            <a
              href="https://github.com/YiJieNG/yjProjects/tree/main/MA"
              target="_blank"
              rel="noopener noreferrer"
            >
              github.com/YiJieNG/yjProjects/tree/main/MA
            </a>
          </li>
        </ul>
      </div>

      {/* Challenges */}
      <div className="section challenges">
        <h2>Challenges</h2>
        <p>
          <p>
            {" "}
            This project demanded a{" "}
            <strong>
              significant investment of time to conduct research, develop
              solutions and maintain high standards of code quality and
              structure.
            </strong>{" "}
            As a group, my peers and I dedicated substantial effort to exploring
            reinforcement learning, iterating through multiple versions before
            finally arriving at a viable solution just before the deadline.
          </p>
          <p>
            This experience taught me the importance of{" "}
            <strong>
              effective time management, persistence in problem-solving and the
              value of collaboration
            </strong>{" "}
            in tackling complex challenges. Additionally, it underscored the
            need for thorough <strong>planning and adaptability </strong>when
            working on innovative and technical projects.{" "}
          </p>
        </p>
        <img
          src={"/assets/projects/MultiAgent/Challenges.png"}
          alt={"Challenges"}
          className="desc-image"
        />
      </div>

      {/*Training Outcome and Showcase*/}
      <div className="section showcase">
        <h2>Training Outcome and Showcase</h2>
        <div className="video-container">
          <video controls width="640" height="360">
            <source src="/videos/MaShowcase.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/*Evaluation*/}
      <div className="section evaluation">
        <h2>Evaluation</h2>
        <p>
          <strong>Metric 1: Steps taken per episode by each agent</strong>
          <ul>
            <li>
              Steps taken is recorded every time the agent is ordered to execute
              the next time-step (move-interact-get feedback)
            </li>
            <img
              src={"/assets/projects/MultiAgent/StepsOne.png"}
              alt={"StepsOne"}
              className="desc-image"
            />
            <li>Result visualised in line graph:</li>
            <img
              src={"/assets/projects/MultiAgent/StepsTwo.png"}
              alt={"StepsTwo"}
              className="desc-image"
            />
            <li>
              It can be seen that in the early phases of the run, due to the
              <strong>high epsilon</strong> value and the agents not having
              enough learning, the steps taken required to complete their
              individual goal can go <strong>as high as 400</strong>. However,
              as the episodes and learning progressed, we can observe a
              <strong>consistent decrease</strong> in the number of steps taken,
              and after a certain point at around ~2500 episodes the graph
              stabilises, with agents settling at{" "}
              <strong>around 20 steps </strong>to complete their individual
              goal. Overall, the graph reflects a positive impact of learning as
              the agents have shown to complete their individual goal as more
              and more learning is executed.
            </li>
          </ul>
        </p>
        <p style={{ paddingTop: "10px" }}>
          <strong>
            Metric 2: Average steps taken to complete one delivery sequence
          </strong>
          <ul>
            <li>
              This metric can be obtained by recording the total number of steps
              taken by <strong>Type2 Agents</strong> since the completion of
              their individual task also marks the completion of a delivery
              sequence. The average can then be obtained by dividing the
              recorded metric by the number of episodes
            </li>
            <img
              src={"/assets/projects/MultiAgent/AvgStepsOne.png"}
              alt={"AvgStepsOne"}
              className="desc-image"
            />
            <li>Result visualised in line graph:</li>
            <img
              src={"/assets/projects/MultiAgent/AvgStepsTwo.png"}
              alt={"AvgStepsTwo"}
              className="desc-image"
            />
            <li>
              The graph above has a similar decreasing trend that shows the
              agent using lesser and lesser steps to complete a delivery
              sequence. This indicates that the agents are learning to be more
              efficient and optimal as episodes and training progressed.
            </li>
            <li>
              Most importantly, we can observe from the graph that initially
              both agents move <strong>according to their own strategy</strong>.
              However after a certain running time, they then{" "}
              <strong>observe, compare and learn</strong> the strategies learnt
              by each other which will converge to an outcome where{" "}
              <strong>both of them agree on a single strategy</strong> and
              continue exploring the world by using that strategy
            </li>
          </ul>
        </p>
      </div>

      {/*Game Theoretic model*/}
      <div className="section game-theoretic-model">
        <h2>Game Theoretic Model</h2>
        <p>
          Apart from the RL model, we were also tasked to create a game-theory
          based implementation of the given problem. After analysing the entire
          problem, we realised that some major simplifications had to be made in
          order to translate it as a game theory problem. More specifically, the
          number of available actions and state representation is too complex to
          capture within the model so we decided to focus on the main
          subproblem: <strong>coordination</strong>.
        </p>
        <p>
          <strong>1. Reward Structure</strong>
          <img
            src={"/assets/projects/MultiAgent/Reward.png"}
            alt={"Reward Structure"}
            className="desc-image"
          />
        </p>
        <p>
          <strong>2. Replicator Dynamics</strong>
          <p>
            (The replicator dynamics is an evolutionary game theory that models
            the evolution of strategies amongst a population)
          </p>
          <img
            src={"/assets/projects/MultiAgent/Replicator.png"}
            alt={"Replicator Dynamics"}
            className="desc-image"
          />
        </p>
        <p>
          <strong>3. Observations</strong>
          <ul>
            <li>
              <p>
                After several iterations, we can see that the population at the
                parts of the grid with the largest negative reward quickly
                reduces which shows initial signs that our replicator is working
                as intended since the probability of duplicating them would be
                extremely low.
              </p>
              <img
                src={"/assets/projects/MultiAgent/ObserveOne.png"}
                alt={"ObserveOne"}
                className="desc-image"
              />
            </li>
            <li>
              <p>
                After a few more iterations we can see that the population has
                basically decided on a fixed set of strategies which aligns with
                the most optimal spots.
              </p>
              <img
                src={"/assets/projects/MultiAgent/ObserveTwo.png"}
                alt={"ObserveTwo"}
                className="desc-image"
              />
            </li>
            <li>
              <p>
                At this point, the replicator should pick a spot to populate
                randomly since they have the same amount of payoff which means
                that each one of them will have the same probability of
                repopulation according to the replicator dynamics formul
              </p>
              <img
                src={"/assets/projects/MultiAgent/ObserveThree.png"}
                alt={"ObserveThree"}
                className="desc-image"
              />
            </li>
          </ul>
        </p>
      </div>

      {/* Footer Section */}
      <div className="footer">
        <p>&copy; Ng Yi Jie</p>
      </div>
    </div>
  );
};

export default MultiAgent;
