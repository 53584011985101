import React, { useState, useEffect } from "react";
import "../../styles/ProjectDetails.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Link } from "react-router-dom";

const BlueVisionary = () => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [currentFeature, setCurrentFeature] = useState(null);

  // Feature data
  const features = [
    {
      title: "Tracker Part A: Dashboard & Facility Locater",
      description:
        "A user-friendly dashboard and locater tool that helps users find recycling facilities and monitor their cleanup activities.",
      banner: "/assets/projects/BlueVisionary/Tracker/Tracker.png",
      data: [
        {
          title: "Dashboard Overview: User Statistics",
          description:
            "Gain insights into your contributions with an overview of total plastic waste collected (in grams and count) and the number of sea turtles potentially protected.",
          image:
            "/assets/projects/BlueVisionary/Tracker/TrackerHomePageTop.png",
        },
        {
          title: "Dashboard Overview: Contribution Analysis",
          description:
            "Visualize your contributions using bar charts, with options to view data for the past week, month, or year by weight or count. Achievements are displayed alongside a milestone comparison to inspire greater participation.",
          image:
            "/assets/projects/BlueVisionary/Tracker/TrackerHomePageBottom.png",
        },
        {
          title: "Plastic Recycling Facility Locator: Overview",
          description:
            "Learn how to properly manage the plastic waste you’ve collected with step-by-step guidance.",
          image: "/assets/projects/BlueVisionary/Tracker/FacilityLanding.png",
        },
        {
          title: "Plastic Recycling Facility Locator: Detailed Search",
          description:
            "Find recycling facilities by selecting your state and suburb, sorted by proximity. Access directions directly from your location.",
          image: "/assets/projects/BlueVisionary/Tracker/Facility.png",
        },
      ],
    },
    {
      title: "Tracker Part B: Plastic Input",
      description:
        "A detailed input system for logging and categorizing collected plastic, providing insights into individual and community contributions.",
      banner: "/assets/projects/BlueVisionary/PlasticInput/PlasticInput.png",
      data: [
        {
          title: "Select Plastic Input Method",
          description:
            "Choose your preferred method to record plastic waste—either by weight or by count.",
          image:
            "/assets/projects/BlueVisionary/PlasticInput/InputWeightChoice.png",
        },
        {
          title: "Image Recognition for Plastic Waste Count",
          description:
            "Upload an image of your collected plastic waste, and let the system automatically detect and count the items for you.",
          image: "/assets/projects/BlueVisionary/PlasticInput/InputImage.png",
        },
        {
          title: "Review Estimated Plastic Count",
          description:
            "Confirm or adjust the system’s estimated count of plastic waste to ensure accuracy.",
          image:
            "/assets/projects/BlueVisionary/PlasticInput/InputImageResult.png",
        },
        {
          title: "Detailed Recognition Results",
          description:
            "View the detected items along with their confidence levels from the image recognition process.",
          image:
            "/assets/projects/BlueVisionary/PlasticInput/InputImageDetails.png",
        },
        {
          title: "Record Plastic Waste by Weight",
          description:
            "Manually input the total weight of collected plastic to calculate your contribution.",
          image: "/assets/projects/BlueVisionary/PlasticInput/InputWeight.png",
        },
        {
          title: "Review Plastic Weight Entry",
          description:
            "Verify and adjust the weight you entered, if needed, before final submission.",
          image:
            "/assets/projects/BlueVisionary/PlasticInput/InputWeightConfirm.png",
        },
        {
          title: "Contribution Summary (Weight)",
          description:
            "See your contribution based on the recycled plastic weight and the estimated number of sea turtles protected.",
          image:
            "/assets/projects/BlueVisionary/PlasticInput/InputWeightResult.png",
        },
        {
          title: "Record Plastic Waste by Count",
          description:
            "Enter the total number of plastic items collected to log your contribution.",
          image: "/assets/projects/BlueVisionary/PlasticInput/InputCount.png",
        },
        {
          title: "Review Plastic Count Entry",
          description:
            "Confirm and modify the count you entered before completing the process.",
          image:
            "/assets/projects/BlueVisionary/PlasticInput/InputCountConfirm.png",
        },
        {
          title: "Contribution Summary (Count)",
          description:
            "View the impact of your recycling efforts, including the number of plastic items recycled and sea turtles potentially saved.",
          image:
            "/assets/projects/BlueVisionary/PlasticInput/InputCountResult.png",
        },
      ],
    },
    {
      title: "Interactive Mini-Game",
      description:
        "Immersive and educational mini-games designed to teach users about the threats marine species face due to pollution.",
      banner: "/assets/projects/BlueVisionary/MiniGame/MiniGame.png",
      data: [
        {
          title: "Mini-Game Landing Page",
          description:
            "An engaging landing page where users can select a difficulty level by choosing a state for the game.",
          image: "/assets/projects/BlueVisionary/MiniGame/MiniGameLanding.png",
        },
        {
          title: "Game Instructions",
          description:
            "Clear and detailed instructions to guide users before they begin the game.",
          image:
            "/assets/projects/BlueVisionary/MiniGame/MiniGameInstruction.png",
        },
        {
          title: "Mini-Game Gameplay",
          description:
            "Immerse yourself as a sea turtle! Dodge plastic items and collect as many points as possible.",
          image: "/assets/projects/BlueVisionary/MiniGame/MiniGamePlay.png",
        },
        {
          title: "Bubble Collision",
          description:
            "Discover educational information or fun facts when colliding with special bubbles that reward extra points!",
          image:
            "/assets/projects/BlueVisionary/MiniGame/MiniGameCollision.png",
        },
        {
          title: "Game Results",
          description:
            "Review your game score, rank, and see how you compare with the top-performing 'sea turtle' in your selected state.",
          image: "/assets/projects/BlueVisionary/MiniGame/MiniGameResult.png",
        },
      ],
    },
    {
      title: "Plastic Pollution Heatmaps",
      description:
        "Dynamic heatmaps showing the distribution of plastic pollution along Australia's coasts and oceans.",
      banner: "/assets/projects/BlueVisionary/Pollution/Pollution.png",
      data: [
        {
          title: "Plastic Pollution in Australia & Distribution by Type",
          description:
            "Explore distribution of plastic pollution along Australia's coasts and oceans.",
          image:
            "/assets/projects/BlueVisionary/Pollution/PollutionLanding.png",
        },
        {
          title: "State and Year Selection",
          description:
            "Select a state and year to explore detailed plastic pollution data visualized in a radar chart.",
          image: "/assets/projects/BlueVisionary/Pollution/PollutionSec1.png",
        },
        {
          title: "Learn About Plastic Types",
          description:
            "Explore knowledge on plastic types like polyethylene and polypropylene, including their origins, potential uses, and suggested alternatives.",
          image: "/assets/projects/BlueVisionary/Pollution/PollutionSec2.png",
        },
        {
          title: "Polymer Trends",
          description:
            "Visualize and compare trends of various polymers in the selected state to uncover insights.",
          image: "/assets/projects/BlueVisionary/Pollution/PollutionSec3.png",
        },
        {
          title: "Actionable Insights from Polymer Trends",
          description:
            "Gain insights into the causes of polymer pollution and learn actionable steps to mitigate it based on the selected state's analysis.",
          image: "/assets/projects/BlueVisionary/Pollution/PollutionSec4.png",
        },
      ],
    },
    {
      title: "Endangered Marine Species in Australia",
      description:
        "Detailed insights into Australia's endangered marine species, their habitats and the threats they face.",
      banner: "/assets/projects/BlueVisionary/MarineLife/MarineLife.png",
      data: [
        {
          title: "Overview of Endangered Marine Species",
          description:
            "Explore marine species across various endangered statuses in different states.",
          image: "/assets/projects/BlueVisionary/MarineLife/MarineLife1.png",
        },
        {
          title: "Threatened Status Selection",
          description:
            "Use the pie chart to select a marine endangered status and view the corresponding species count visualized in a bar chart.",
          image: "/assets/projects/BlueVisionary/MarineLife/MarineLife2.png",
        },
        {
          title: "Species List by Threatened Status",
          description:
            "Click on the bar chart to view a list of collapsible cards with details about species under the selected status.",
          image: "/assets/projects/BlueVisionary/MarineLife/MarineLife3.png",
        },
        {
          title: "Marine Species Details",
          description:
            "Expand a collapsible card to access detailed information about a species, including its scientific name, common name, habitats, and threats.",
          image:
            "/assets/projects/BlueVisionary/MarineLife/MarineLifeDetails.png",
        },
      ],
    },
    {
      title: "Quiz",
      description:
        "Interactive quizzes that test users' knowledge about marine conservation and environmental sustainability.",
      banner: "/assets/projects/BlueVisionary/Quiz/Quiz.png",
      data: [
        {
          title: "Quiz Landing Page",
          description:
            "A sleek and modern landing page design featuring a title, description and a call-to-action button to begin the quiz.",
          image: "/assets/projects/BlueVisionary/Quiz/Quiz.png",
        },
        {
          title: "Quiz Question",
          description:
            "A clean and intuitive design for quiz questions, allowing users to choose from four multiple-choice options.",
          image: "/assets/projects/BlueVisionary/Quiz/Question.png",
        },
        {
          title: "Review (Correct Answer)",
          description:
            "View the correct answer along with a detailed explanation. A bar chart illustrates how other users responded to the question.",
          image: "/assets/projects/BlueVisionary/Quiz/AnswerCorrect.png",
        },
        {
          title: "Review (Incorrect Answer)",
          description:
            "An example of the review interface when an incorrect answer is selected, with detailed feedback provided.",
          image: "/assets/projects/BlueVisionary/Quiz/AnswerIncorrect.png",
        },
        {
          title: "Quiz Results",
          description:
            "Review your total score and see how well you performed!",
          image: "/assets/projects/BlueVisionary/Quiz/Result.png",
        },
      ],
    },
  ];

  const openOverlay = (feature) => {
    setCurrentFeature(feature);
    setIsOverlayOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    setCurrentFeature(null);
    document.body.style.overflow = "auto";
  };

  // Clean up overflow style when component unmounts
  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="project-details-container">
      {/* Banner Section */}
      <div className="project-image">
        <img
          src={"/assets/projects/BlueVisionary/LandingPage.png"}
          alt={"BlueVisionary"}
          className="project-image"
        />
      </div>

      {/* Overview Section */}
      <div className="section overview">
        <h2>Overview</h2>
        <p>
          The *Blue Visionary* project is an interactive and educational
          platform designed to promote marine conservation in Australia. It
          provides <strong>essential resources </strong>and{" "}
          <strong>innovative tools</strong>, such as detailed insights on
          endangered marine species, engaging mini-games, visualizations of
          plastic pollution, and interactive features like recycling trackers
          and facility locators. This project empowers individuals to{" "}
          <strong>actively contribute</strong> to ocean protection efforts while
          raising awareness about marine ecosystems.
        </p>
      </div>

      {/* Website Info Section */}
      <div className="section website-info">
        <h2>Website Information</h2>
        <ul>
          <li>
            <strong>Website name: </strong>
            <a
              href="https://marine.bluevisionary.studio"
              target="_blank"
              rel="noopener noreferrer"
            >
              marine.bluevisionary.studio
            </a>
          </li>
          <li>
            <strong>Username: </strong>hustleSquad
          </li>
          <li>
            <strong>Password: </strong>HustleSquadTP17!
          </li>
          <li>
            <strong>
              GitHub{" "}
              <i className="fab fa-github" style={{ marginRight: "5px" }}></i>:{" "}
            </strong>{" "}
            <a
              href="https://github.com/YiJieNG/BlueVisionary"
              target="_blank"
              rel="noopener noreferrer"
            >
              github.com/YiJieNG/BlueVisionary
            </a>
          </li>
        </ul>
      </div>

      {/* Problem Statement Section */}
      <div className="section problem-statement">
        <h2>Problem Statement</h2>
        <p>
          Marine ecosystems in Australia face significant threats, including{" "}
          <strong>
            116 human-induced, 147 environmental, and 44 socio-political
            challenges
          </strong>
          (Laubenstein et al., 2023). Although 62% of young Australians
          recognize the importance of marine conservation, 70% lack the deeper
          knowledge needed to take meaningful action.
        </p>
      </div>

      {/* Target Audience Section */}
      <div className="section audience">
        <h2>Target Audience</h2>
        <p>
          The platform is tailored for{" "}
          <strong>young Australians aged 15-25 </strong>who reside in urban
          areas with limited exposure to marine environments. Despite their busy
          lifestyles, they are motivated to protect Australia’s unique marine
          biodiversity and are eager to find accessible ways to engage in
          conservation efforts.
        </p>
      </div>

      {/* Features Section */}
      <div className="section features">
        <h2>Features</h2>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div
              className="feature"
              key={index}
              onClick={() => openOverlay(feature)}
            >
              <div className="feature-content">
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
              <div className="feature-image">
                <img src={feature.banner} alt={feature.title} />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Overlay */}
      {isOverlayOpen && currentFeature && (
        <div className="overlay">
          <div className="carousel-container">
            {" "}
            <button className="close-button" onClick={closeOverlay}>
              &times;
            </button>
            <Carousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              emulateTouch={true}
              renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                  <button
                    className="carousel-arrow prev-arrow"
                    onClick={onClickHandler}
                  >
                    &#8249;
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                  <button
                    className="carousel-arrow next-arrow"
                    onClick={onClickHandler}
                  >
                    &#8250;
                  </button>
                )
              }
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                return (
                  <li
                    className={`carousel-dot ${isSelected ? "selected" : ""}`}
                    onClick={onClickHandler}
                    key={index}
                  />
                );
              }}
            >
              {currentFeature.data.map((item, idx) => (
                <div key={idx}>
                  <h2 className="carousel-title">{item.title}</h2>

                  <img
                    src={item.image}
                    alt={item.title}
                    style={{ padding: "0 50px 20px" }}
                  />
                  <p className="carousel-description">{item.description}</p>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}

      {/* Technologies Section */}
      <div className="section technologies">
        <h2>Technologies Used</h2>
        <p>React, Flask, MySQL, Bootstrap, Javascript, Python, YOLOv9c</p>
      </div>

      {/* Challenges Section */}
      <div className="section challenges">
        <h2>Challenges</h2>
        <p>
          Initially, our team faced a <strong>shortage of members</strong>, as
          one member dropped the unit. With only{" "}
          <strong>two out of four members experienced in coding</strong>, we
          were tasked with completing{" "}
          <strong>two complex features within two weeks</strong>. Additionally,
          the selected project topic—marine conservation—presented unique
          challenges, as it required{" "}
          <strong>linking human actions to the theme of life underwater</strong>
          .
        </p>
        <p>
          These obstacles taught us valuable lessons in{" "}
          <strong>
            time management, project coordination, communication, teamwork, web
            hosting, and design.
          </strong>
        </p>
      </div>

      {/* Image Recognition Section */}
      <div className="section image-recognition">
        <h2>Image Recognition</h2>
        <p>
          This feature integrates advanced machine learning techniques to
          identify and categorize collected waste items from uploaded images.
          Users can track their plastic reduction impact directly through this
          functionality.{" "}
        </p>
        <p>
          <Link to="/Projects/ImageRecognition" className="projects-link">
            Checkout the details overhere
          </Link>
        </p>
        <img
          className="desc-image"
          src={"/assets/projects/BlueVisionary/ImageRecognition.png"}
          alt="Object Detection using Image recognition"
        />
      </div>

      {/* Videos Section */}
      <div className="section videos">
        <h2>Product Video</h2>
        <p>
          This video highlights the key features of the platform, showcasing
          technical and design expertise. It demonstrates the skills involved in
          building a seamless, user-friendly experience for marine conservation
          enthusiasts.
        </p>
        <div className="video-container">
          <iframe
            width="640"
            height="360"
            src="https://www.youtube.com/embed/I8kLDPHxoOs"
            title="Product Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <p style={{ padding: "15px 0" }}></p>
        <h2>Learning Journey Video</h2>
        <p>
          This video encapsulates the team’s learning journey, design process,
          and problem-solving approaches. Created entirely by me, it emphasizes
          skills such as storytelling, video editing, and design thinking.
        </p>
        <div className="video-container">
          <iframe
            // width="640"
            // height="360"
            src="https://www.youtube.com/embed/eGw5cNQLtE0"
            title="Product Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* Footer Section */}
      <div className="footer">
        <p>&copy; BlueVisionary. All rights reserved.</p>
      </div>
    </div>
  );
};

export default BlueVisionary;
